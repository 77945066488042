@media screen and (max-width: 720px) {

    body {
        font-family: "Helvetica Neue", sans-serif;
    }
    
    h1, h2, h3, h4, a, b {
        text-align: center;

    }

    .wrapper {
        margin-top: 60px;
    }

   
    #menu-list {
        display: none;
    }



    .mobile-buttons {
        display: inline-block;
        /* float: right; */
        text-align: center;
        margin-right: .5em;
    }
    .mobile-buttons i{
        width: 100%;
        display: inline-block;
        text-align: center;
    }

    .search-mobile {
        position: relative;
        /* padding-bottom: 1em; */
        padding-top: .5em;

    }

    .lupa-mobile {
        position: absolute;
        right: 2em;
        top: 1em;
        z-index: 5;
    }

    .search-mobile input {
        margin-top: 0;
        margin-left: 5%;
        width: 85%;
        font-size: 1em;
        display: block;
        padding: .5em;
        border: 1px solid grey;
        padding-top: .6em;
        padding-bottom: .6em;

    }


    .content {
        /* width: 100%; */
        padding: 1em .5em;
    }

    .right-content {
        width: 100%;
    
    }
    
    .left-content {
        width: 100%;
    
    }

    .home-static-banner {
        width: 100%;

    }

    .home-static-banner img {
        width: 100%;
    }

    .news-slide-container img {
        width: 90%;

    }

    .slick-slider {
        overflow: hidden;

    }

    .product-container {
        width: 100%;
        position: relative;
        height: auto;  

    }

    .product-container .add-to-cart-button {
        position: relative;
        left: auto;
        margin-top: 1em;
        font-size: 1.2em;
        padding-top: 0.5em;
        padding-bottom: .5em;
   
    }
    

    .product-container img {
        position: relative;
        width: 50%;
        max-height: none;
        
    }

    .product-single {
        margin: 1em;
    }

    .product-single-images {
        width: 100%;
    }

    .product-signgle-cart {
        width: 100%;
    }

    .product-signgle-cart .shop-button {
        width: 100%;
    }

    .product-single-description {
        width: 100%;
        margin-left: 0;
    }

    .cart-submit {
        font-size: 1.1em;
        
    }


    .right-content p {
        margin-left: 12px;
        margin-right: 12px;

    }

    .right-content input {
        width: 94%;

    }



.top-products-sidebar {
    display: none;
}

.news-sidebar {
    display: none;
}

.categories-container {
    border: none;
    border-bottom: 1px solid gray;
    padding: 0;
    width: 100%;
    height: auto;
}

.categories-container ul {
    margin: 0;
    padding: 0;
}

.categories-container ul li {
    color: #999;
    display: block;
}

.categories-container h3{
    text-align: left;
    padding-left: 2em;
}

.categories-container a{
    /* font-size: 1.5em; */
    /* margin-top: 1em; */
    text-align: center;
    margin: 0.1em;
    /* margin-left: 3em; */
}

/* .categories-container a{ */

.product-container {
    font-size: 1em;
}

.product-container h3 {
    font-size: 1.7em;
    max-height: none;
    margin-top: 2em;
}

/* Product stickers */
.sticker-container-products{
    right: 3.3em;
    width: 40%;
}

.sticker-container-products p{
    top: .25em;
    left: 43%;
}

.sticker-container-product{
    right: 0em;
    margin-top: 1em;
}

.sticker-container-product p{
    top: .2em;
    left: 42%;
    font-size: 1.8em;
}

.product-container .add-to-cart-button {
    font-size: 1.2em;
    font-weight: bold;
}

.cart-product-head {
    margin-top: 0;
}

.cart-product {
    /* padding-top: .8em;
    padding-bottom: .8em; */
    padding-bottom: 1em;
}

.cart-product-head .title, .cart-product-head .price, .cart-product-head .quantity {
    display: none;
}

.cart-product div{
    padding-top: .5em;
    padding-bottom: .5em;
}

.cart-product .image {
    width: 20%;
}

.cart-product .title {
    width: 70%;
}

.cart-product .quantity {
    width: 40%;
}
.cart-product .quantity span {
    margin: auto;
}

.cart-product .price {
    width: 30%;
    font-size: 1.2em;
}

.cart-total {
    width: 90%;
    margin-left: 5%;
}

.cart-total h3{
    text-align: right;
}

    .cart-inputs {
        width: 100%;
        /* margin-right: 3%; */
        
    }

.cart-inputs input {
    display: inline-block;
    position: relative;
    width: 90%;
    font-size: 1.2em;
    padding-top: .5em;
    border: 1px solid #cccccc;
}

.cart-inputs h3 {
    text-align: left;
    margin-left: 1em;
}

.cart-inputs-billing {
    width: 90%;
    margin-left: 5%;
    padding-bottom: 2em;
    border-bottom: 1px solid rgb(179, 179, 179);
}
.cart-inputs-billing h4{
    text-align: left;
}

.cart-inputs-billing div {
    width: 80%;
}

.town-checkbox{
    margin-left: .5em;
}

input#dStreetNumber{
    width: 75%;
}

.cart-submit {
    padding: 1em;
}

.cart-submit button {
    font-size: 2em;
}




.product-left, .product-mid, .product-right {
    width: 90%;
    margin-left: 5%;
    text-align: center;
    padding-left: 0;
}

.comments {
    width: 90%;
    margin-left: 5%;
}


.registration {
    width: 100%;
    font-size: 1.2em;
}

.registration div{
    width: 100%;
}

.registration h2{
    width: auto;
}

.registration div:last-child {
    margin-left:0;
    padding-left:0;
}

.registration input {
    width: 90%;
    margin-left: 2.5%;
    border: 1px solid gray;
}

.registration p{
    width: 90%;
    margin-left: 5%;
}

.modal {
    right: 10%;
    width: 80%;
    z-index: 1;
}

.top-books {
    display: none;
}

.popup-content {
    width: 100% !important;
    height: 70%;
    margin-top: 1em !important;
}

.popup-slider-cont {
    width: 100%;
    height: 70%;
    left: 0;
    top: 15%;
    padding: 2%;
}

.popup-slider-cont .slick-slider img {
    max-width: 98%;
}

.popup-content .popup-prev, .popup-content .popup-next {
    font-size: 1.2em;
}

.secondary-images-cont {
    width: 90%;
    margin-left: 5%;
}
.secondary-images-cont-mobile {
    display: block;
}
.secondary-images-cont-desktop {
    display: none;
}
.secondary-image-cont{
    /* width: 23%; */
    margin-right: 1%
}

.popup-slider-cont .slick-slider {
    width: 96%;
}


.secondary-image-cont-mobile{
    display: block;
    margin-top: 1em;
    text-align: left;
}
.secondary-image-cont-desktop{
    display: none;
}

.product-video-cont {
    width: 100%;
}

.product-video-cont iframe{
    width: 100%;
    height: auto;
}

.news-slider-container {
    margin: 0;
}

.pdf-iframe {
    width: 90%;
    margin-left: 5%;
    height: 500px;
}




.home-product {
    width: 100%;
    margin: 0;
    margin-top: 2em;
    border-top: 1px solid #ccc;
    padding-top: 1em;
}

.home-product-image {
    margin: auto;
}

.shop-button {
    width: 60%;
    margin: auto;
    padding-top: .5em;
    padding-bottom: .5em;
}

.home-product p {
    height: unset;
    line-height: auto;
    text-align: center;
}

.home-product-info {
    margin: 1em auto;
    width: 270px;
}

.home-product-price {
    text-align: center;
}


.shop-button-order {
    display: block;
    margin: auto;
}

.data-left, .data-right {
    width: 100%;
    text-align: center;
}

.footer-bottom-left {
    width: 92%;
}

} /* media end */