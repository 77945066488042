/* Admin */

@import url('https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400&display=swap');

.half {
    display: inline-block;
    width: 48%;
    vertical-align: top;
    padding: 1%;
}

.half .half-img{
    width: auto!important;
    max-width: 400px;
    max-height: 250px;
}

.admin {
    font-family: sans-serif;
    font-size: 16px;
}

.admin-content{
    display: inline-block;
    width: 73%;
    padding: .5%;
    vertical-align: top;
    margin-left: 10%;
    padding-bottom: 5em;
}

.admin .admin-content h1{
    font-family: 'Merriweather';
    text-align: center;
    color: black;
}


.admin-content label {
    margin-bottom: .5em;
    font-family: 'Merriweather';
}

.admin-content input{
    padding: .5em;
    color: rgb(65, 65, 65);
    font-weight: normal;
    margin-top: .5em;
    margin-bottom: 0;
}

.admin .ql-editor {
    height: 20em;
}


/* Admin header */
.admin-sidebar {
    height: 100%;
    width: 10%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #23282D;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    text-align: left;
}

.admin-logout {
    position: absolute;
    bottom: 1px;
    cursor: pointer;
    border: none;
}

.admin-sidebar-content {
    padding: 5px;
}

.admin-controls {
    height: 100%;
    width: 15%;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color:whitesmoke;
    overflow-x: hidden;
    /* overflow-y: scroll; */
    text-align: left;
    padding: .5%;
    border-left: 1px solid #ddd;
}

.admin .button {
    cursor: pointer;
    border-radius: .2em;
    background: rgb(58, 138, 192);
    color: white;
    display: inline-block;
    padding: .4em 1em;
    margin: 10px 0;
}

.admin-controls .button {
    display: block;
    text-align: center;
}
.admin .button:hover {
    opacity: .8;
}

.admin .html-location-button {
    cursor: pointer;
    border-radius: .2em;
    background: rgb(58, 138, 192);
    color: white;
    display: inline-block;
    padding: .4em 1em;
    margin-right: .5em;
    margin-bottom: .5em;
}

.admin .html-location-button:hover {
    opacity: .8;
}

.admin .html-location-button.active {
    background: rgb(41, 98, 136);
}

.admin label {
    font-size: .9em;
    color: #666;
}

.admin-controls hr {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.admin-product-categories {
    border: 1px solid gray;
    background: white;
    border-radius: .3em;
    padding: .5em;
}
.button-category-add {
    display: inline-block;
    /* text-align: center; */
    margin: .2em;
    padding: .1em 0.4em .1em .2em;
    background: #a5a5a5;
    color: white;
    border-radius: .2em;
    cursor: pointer;
}

.admin-controls label {
    display: inline-block;
    vertical-align: top;
    top: .15em;
    position: relative;
    margin-left: .2em;
}
.admin-controls input[type=checkbox] {
    display: inline-block;
}

.admin h3 {
    font-family: 'Merriweather';
    font-weight: normal;
    margin-bottom: .5em;
    color: #333;
}

.admin input[type=text] {
    margin-top: 0;
}

.admin-login label {
    display: block;
}

.admin-login {
    text-align: center;
    padding-top: 2em;
}
.admin-login input{
    margin-top: .5em;
    margin-bottom: 1em;
    width: 382px;
    /* border-color: rgb(189, 189, 189); */
    border: 1px solid #ccc;
    border-radius: .2em;
    /* display: none; */
}
.admin-login-button-container {
    text-align: right;
}

.admin-login .admin-login-inputs {
    width: 400px;
    margin: auto;
    display: block;
    position: relative;
    padding: 2em;
    background: whitesmoke;
    text-align: left;
    border-radius: .2em;
}

.admin-login .admin-login-inputs h1 {
    text-align: center;
}

.admin-product-categories {
    list-style-type: none;
    padding: 0;
}


.admin-sidebar ul {
    list-style-type: none;
    padding: 0;
   
}

.admin-sidebar ul li {
    color: #ccc;
    font-weight: 200;
    margin-bottom: .5em;
    border-bottom: 1px solid #484848;
    padding-bottom: .5em;
}
.admin-sidebar ul li:hover {
     color: #fff;
     font-weight: normal;
}

.admin .admin-item-active {
    font-weight: normal;
    color: #fff;
}

.admin-sidebar a {
    text-decoration: none;
    color: inherit;
}

.admin table {
    width: 100%;
    border-collapse: collapse;
}

.admin table tr:nth-child(even) {
    background: whitesmoke;
}

.admin table td {
    padding: .5em;
}

.admin table th:last-child {
    text-align: right;
}

.admin table td:last-child {
    text-align: right;
}
  
/* .admin-sidebar a {
    padding: 6px 8px 6px 16px;
    text-decoration: none;

    color: white;
    display: block;
}
  
.admin-sidebar a:hover {
    color: #f1f1f1;
} */
  
/* Admin media */

.admin-media-img {
    /* max-width: 75px;
    max-height: 75px;
    height: auto;
    display: inline-block;
    margin: .5em; */
    display: block;
    max-width: 100%;
    max-height: 100%;
}

/* Admin variations */
.variations-content {
    position: relative;
    width: 100%;
}

.variations-left {
    width: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
}

.variations-right {
    width: 50%;
    display: inline-block;
    position: absolute;
    top: 0;
}

/* Admin pages */
.admin-pages-content {
    position: relative;
    width: 100%;
}

/* Admin categories */
.categories-content {
    position: relative;
    width: 100%;
}

.categories-left {
    width: 50%;
    display: inline-block;
    position: absolute;
    left: 0;
}

.categories-right {
    width: 40%;
    display: inline-block;
    position: absolute;
    top: 0;
    margin-left: 10%;
}

/* Admin product */
.product-thumbnail {
    width: 100%;
}

.product-gallery-thumbnail {
    display: inline-block;
    width: 98%;
    vertical-align: top;
    margin: 1%;
    margin-right: 0;
}

.product-media-container {
    width: 70%;
    overflow-y: scroll;
    height: 90%;
    background-color: whitesmoke;
    z-index: 10;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: .5em;
    border: 1px solid gray;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.72);
}

.product-media-container h2{
    margin-top: 0;
}

.youtube-upload-container {
    width: 70%;
    height: 25%;
    background-color: whitesmoke;
    z-index: 10;
    position: fixed;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: .5em;
    border: 1px solid gray;
    box-shadow: 0px 0px 15px -3px rgba(0,0,0,0.72);
}

.youtube-upload-container h2{
    margin-top: 0;
}

.selected{
    border: 2px solid black;
}

.not-selected{
    border: 2px solid transparent;
}

.edit-products-tab {
    height: 100%;
    width: 260px;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: lightgray;
    overflow-x: hidden;
    padding-top: 20px;
}

.admin .pagination {
    text-align: center;
    margin: 1em;
}

.admin-product-active label, .admin-product-active input{
    display: inline-block;
}

.admin-product-featured label, .admin-product-featured input{
    display: inline-block;
}

.admin-category-active label, .admin-category-active input{
    display: inline-block;
}

.admin-page-active label, .admin-page-active input{
    display: inline-block;
}

.admin-product-gallery {
    position: relative;
}

.admin-product-gallery-image-container {
    position: relative;
    width: 32%;
    display: inline-block;
}

.product-gallery-delete {
    position: absolute;
    top: 2px;
    left: 33%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-move-left {
    position: absolute;
    top: 2px;
    left: 6%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-move-right {
    position: absolute;
    top: 2px;
    left: 63%;
    width: 30%;
    opacity: .5;
    cursor: pointer;
}

.product-gallery-delete:hover, .product-gallery-move-left:hover, .product-gallery-move-right:hover {
    opacity: 1;
}

.admin-media-gallery-image-container{
    /* position: relative;
    display: inline-block; */
    position: relative;
    display: inline-block;
    width: 12%;
    height: 6em;
    border: 1px solid #c9c9c9;
    vertical-align: top;
    margin: 0.2em;
    box-sizing: border-box;
    padding: 0.2em;
    background: #ebebeb;
    border-radius: 0.2em;
}

.media-gallery-delete {
    position: absolute;
    top: 2px;
    right: 2px;
    width: 20px;
    opacity: .5;
    cursor: pointer;
}

.media-gallery-delete:hover {
    opacity: 1;
}

.admin-content input.admin-product-input-name {
    font-size: 2em;
    border: none !important;
    text-align: center;
    font-family: 'Merriweather';
    color: black;
    font-weight: bold;
}

/* Admin banners */

.banner-container{
    width: 100%;
    position: relative;
}

.banner-image {
    position: relative;
    /* vertical-align: top; */
    text-align: center;
    width: 60%;
    height: 200px;
    border: 1px solid rgb(202, 202, 202);
    /* background: rgb(224, 224, 224); */
    display: block;
    margin: 2px;
    border-radius: 3px;
    display: inline-block;
}

.banner-image-hide{
    opacity: .5;
}

.banner-image img {
    max-width: 100%;
    max-height: 100%;
}

.banner-controls input, .banner-controls label {
    display: inline-block;
}

.banner-actions{
    width: 25%;
    display: inline-block;
    position: absolute;
}

.banner-actions.button{
    width: 100%;
}

.admin-banner-link {
    position: relative;
    margin: 20px 0;
}

.admin-banner-link label {
    width: 7%;
    display: inline-block;
}

.admin-banner-link input {
    width: 77%;
    display: inline-block;
    margin-right: 1%;
}

.admin-banner-link .button {
    width: 8%;
}

/* Product tooltip */
.tooltip {
    position: relative;
    display: inline-block;
    margin-left: .3em;
}
  
.tooltip .tooltiptext {
visibility: hidden;
width: 120px;
background-color: #a5a5a5;
color: #fff;
text-align: center;
border-radius: 6px;
padding: 5px 0;
position: absolute;
z-index: 1;
bottom: 150%;
left: 50%;
margin-left: -60px;
}
  
.tooltip .tooltiptext::after {
content: "";
position: absolute;
top: 100%;
left: 50%;
margin-left: -5px;
border-width: 5px;
border-style: solid;
border-color: #a5a5a5 transparent transparent transparent;
}
  
.tooltip:hover .tooltiptext {
visibility: visible;
}

/* Order page */
.admin-order {
    position: relative;
    display: flex;
    margin: 3em 0;
}

.admin-order-billing, .admin-order-shipping {
    width: 50%;
    display: inline-block;
}

.media-module img{
    width: 64px;
    display: inline-block;
    margin: .3em;
    cursor: pointer;
    vertical-align: middle;
}
.media-module img:hover{
    opacity: .8;
}
.media-module {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    z-index: 10;
    background: white;
    padding: .5em;
    border-radius: .5em;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.shadow {
    box-shadow:
    5px 10px #000000,
}
.media-module .head {
    display: block;
    height: 30%;
}
.media-module .body {
    display: block;
    height: 58%;
    overflow-y: scroll;
}
.media-module .media-footer {
    display: block;
    height: 8%;
}

.media-preview-image {
    position: fixed;
    top: 10%;
    left: 10%;
    height: 80%;
    width: 80%;
    z-index: 10;
    background: white;
    padding: .5em;
    border-radius: .5em;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}

.media-preview-image .media-preview-footer {
    display: block;
    height: 10%;
}

.media-preview-image .media-preview-head {
    display: block;
    height: 90%;
    position: relative;
}

.media-preview-image img{
    max-width: 100%!important;
    height: auto;
}

.document-list {
    padding: 1em;
}

.document-active {
    color: #3a8ac0;
    font-weight: bold;
    font-size: .8em;
    border-bottom: 1px solid #bebebe;
}

.document {
    color: #3a8ac0;
    padding: .5em;
    cursor: pointer;
    font-size: .8em;
    border-bottom: 1px solid #bebebe;
}

.document-item {
    color: #3a8ac0;
    padding: .5em;
    cursor: pointer;
    font-size: .8em;
    border-bottom: 1px solid #bebebe;
}

.ql-snow .ql-editor img {
    max-width: 80% !important;
}